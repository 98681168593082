// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CalendarOutlined } from '@ant-design/icons';

// icons
const icons = {
  CalendarOutlined
};

// ==============================|| MENU ITEMS - SURVEYS ||============================== //

const Surveys = {
  id: 'group-surveys',
  type: 'group',
  children: [
    {
      id: 'surveys',
      title: <FormattedMessage id="surveys" />,
      type: 'item',
      url: '/surveys',
      icon: icons.CalendarOutlined
    }
  ]
};

export default Surveys;
