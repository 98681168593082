// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { InsertRowAboveOutlined } from '@ant-design/icons';

// icons
const icons = {
  InsertRowAboveOutlined
};

// ==============================|| MENU ITEMS - BILLING ||============================== //

const Billing = {
  id: 'group-billing',
  type: 'group',
  children: [
    {
      id: 'billing',
      title: <FormattedMessage id="billing" />,
      type: 'item',
      url: '/billing',
      icon: icons.InsertRowAboveOutlined
    }
  ]
};

export default Billing;
