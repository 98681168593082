// project import
import projects from './projects';
import surveys from './surveys';
import surveyCredits from './survey-credits';
import billing from './billing';
import settings from './settings';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [projects, surveys, surveyCredits, billing, settings /*, widget, applications, formsTables, chartsMap, pages, other*/]
};

export default menuItems;
