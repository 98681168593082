// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ShoppingCartOutlined } from '@ant-design/icons';

// icons
const icons = {
  ShoppingCartOutlined
};

// ==============================|| MENU ITEMS - SURVEY CREDITS ||============================== //

const SurveyCredits = {
  id: 'group-survey-credits',
  type: 'group',
  children: [
    {
      id: 'survey-credits',
      title: <FormattedMessage id="survey-credits" />,
      type: 'item',
      url: '/survey-credits',
      icon: icons.ShoppingCartOutlined
    }
  ]
};

export default SurveyCredits;
