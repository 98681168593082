import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';

// types
import { PropelAuthProps } from 'types/propelAuth';

// initial state
const initialState: PropelAuthProps = {
  token: '',
};

// ==============================|| SLICE - PROPELAUTH||============================== //

export const fetchPropelAuth = createAsyncThunk('', async () => {
  const response = await axios.get('/api/propelAuth/dashboard');
  return response.data;
});

const propelAuth = createSlice({
  name: 'propelAuth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    }
  },
});

export default propelAuth.reducer;

export const { setToken } = propelAuth.actions;
