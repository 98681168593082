export default async function request(url: string, method: string, headers: any, body?: any) {
  const settings = { method, headers, body };

  try {
    const response = await fetch(url, settings);
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }
}
