// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { TableOutlined } from '@ant-design/icons';

// icons
const icons = {
  TableOutlined
};

// ==============================|| MENU ITEMS - PROJECTS ||============================== //

const Projects = {
  id: 'group-projects',
  type: 'group',
  children: [
    {
      id: 'projects',
      title: <FormattedMessage id="projects" />,
      type: 'item',
      url: '/projects',
      icon: icons.TableOutlined
    }
  ]
};

export default Projects;
