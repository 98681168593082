// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LoginOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined
};

// ==============================|| MENU ITEMS - SETTINGS ||============================== //

const Settings = {
  id: 'group-settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.LoginOutlined
    }
  ]
};

export default Settings;
